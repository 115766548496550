import { Container } from '@material-ui/core';
import React from 'react';
import Menu from './Menu';
import TopPage from "./TopPage";
import ProjectList from "./ProjectList";
import Footer from "./Footer";
import styled from 'styled-components';
// @ts-ignore

export const ContainerWrapper = styled.div`
  width: 80%;
  margin: 40px auto;
  @media screen and (max-width:700px){
    width: 90%;
  }
  & img{
    width: 100%;
    margin: 60px 0 100px;
  }
`
const Separator = styled.div`
  height: 220px;
`

function App() {
    return (
        <>
            <ContainerWrapper>
                <Menu/>
                <TopPage/>
                <Separator/>
                <ProjectList/>
                <Footer />
            </ContainerWrapper>
        </>
    );
}

export default App;
