import { Grid } from '@material-ui/core';
import React from 'react';
import styled from "styled-components";
const GridWrapper = styled(Grid)`
  margin-top: 40px;
`
const GridMenu = styled(Grid)`
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: all ease-in .2s;
  &:hover{
    //trouver un hover
  }
  & a{
    text-decoration: none;
    color: #fff;
  }
`

function Menu() {
    return (
        <>
            <GridWrapper
                container
                xs={12}
                spacing={3}
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <GridMenu className="MenuList" item><a href="#work">Work</a></GridMenu>
                <GridMenu className="MenuList" item><a href="AnnaBristeauResume.pdf" download>About</a></GridMenu>
                <GridMenu className="MenuList" item><a href="mailto:contact@anna-bristeau.fr">Contact</a></GridMenu>
            </GridWrapper>
        </>
    );
}
export default Menu;
