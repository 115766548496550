import Sinch from '../assets/sinch.png';
import Ldp from '../assets/ldp.png';
import YWW from '../assets/yww.png';

export default[
    {
        name: 'Mobile SaaS Marketing Platform',
        image: `${Sinch}`,
        pageLink: '',
        className: 'sinch'
    },
    {
        name: 'Social readers platform',
        image: `${Ldp}`,
        pageLink: 'LivresDeProches',
        className: 'ldp'
    },
    {
        name: 'Wine Testing Application',
        image: `${YWW}`,
        pageLink: 'YesWeWine',
        className: 'yww'
    },
    /*{
        name: 'Yet Another Agile Lab',
        image: ``,
        pageLink: 'Yaal.jsx',
        className: 'yaal'
    },
    {
        name: 'Crossfit clothes brand',
        image: ``,
        pageLink: 'HeroWod.jsx',
        className: 'wod'
    },*/

]