import React from 'react';
import styled from 'styled-components';
import dataProject from './Project/dataProject';
import Project, {InputPassword, InputPwd, LabelPassword, ModalContent, ModalTitle,
    PasswordButton,
    ProjectCta, ProjectName, ProjectWrapperWhite} from './Project/Project';
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ClearIcon from "@material-ui/icons/Clear";
import Modal from '@material-ui/core/Modal';

const ProjectListWrapper = styled.div`

`
const ModalWhiteWrapper = styled(Modal)`
  width: 40%;
  margin: 10% auto;
`

const ModalWhite = styled(ModalContent)`
  &&{
    background: white;
  }
`
function ProjectList() {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <ModalWhiteWrapper open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <ModalWhite>
                    <ClearIcon onClick={handleClose} />
                    <ModalTitle>Currently working on</ModalTitle>
                    <LabelPassword>Enter project password</LabelPassword>
                    <InputPwd>
                        <InputPassword type="password"/>
                        <PasswordButton onClick={() => { alert('Permission denied')}} color="primary">Unlock</PasswordButton>
                    </InputPwd>
                </ModalWhite>
            </ModalWhiteWrapper>
            <ProjectListWrapper>
                {dataProject.map(project => (
                    <Project
                        className={project.className}
                        name={project.name}
                        image={project.image}
                        pageLink={project.pageLink}
                    />
                ))}
            </ProjectListWrapper>
            <ProjectWrapperWhite id="work" onClick={() => { alert('Not available yet')}}>
                <ProjectName>White paper</ProjectName>
                <ProjectCta to="/">Read the case study <ArrowRightAltIcon/></ProjectCta>
            </ProjectWrapperWhite>
        </>

    );
}

export default ProjectList;
