import React from 'react';
import {ContainerWrapper} from "../App";
import Menu from "../Menu";
import LdpCover from "../assets/ldpCover.png";
import {
    Attributes, BlackContainerWrapper,
    Circle,
    Cover,
    GoBackLink,
    LeftWrapper,
    OverviewWrapper,
    ProductEvoWrapper,
    ProjectSubText,
    ProjectTitle,
    RightWrapper,
    WhiteContainerWrapper
} from './LivresDeProches';
import YwwCover from "../assets/ywwCover.png";
import ywwCommunity from "../assets/ywwCommunity.png";
import ywwBusiness from "../assets/ywwBusiness.png";
import ywwDegustation from "../assets/ywwDegustation.png";
import ywwDashboard from "../assets/ywwDashboard.png";
import ywwDSS from "../assets/ywwDSS.png";

function YesWeWine() {
    return (
        <>
            <ContainerWrapper>
                <GoBackLink to="/">Go back to main page</GoBackLink>
                <Menu/>
                <Circle/>
                <ProjectTitle>Wine testing application</ProjectTitle>
                <ProjectSubText>Yes We Wine is an application offering an easy approach to wine tasting for a wide range of persons, including the experts for whom this could be an essential tool thanks to dedicated features. This app was the final product of a team for the 2018/2019  Digital Campus Grands Projets. With this project I was awarded for Project Manager of the year and the favorite project for the jury and students.</ProjectSubText>
            </ContainerWrapper>
            <Cover>
                <img src={YwwCover} alt=""/>
            </Cover>
            <ContainerWrapper>
                <OverviewWrapper>
                    <LeftWrapper>
                        <ProjectTitle>Overview</ProjectTitle>
                        <ProjectSubText>This project was launched by the school and targeted all the different years. This was a real challenge for me since it was the second time I had taken up the project manager challenge.<br/> I was deeply motivated to prove  that I deserved the title of project manager of the year. That’s why I did my utmost to carry out this project throughout 6 months of intensive work.</ProjectSubText>
                    </LeftWrapper>
                    <RightWrapper>
                        <Attributes>
                            <span>Teams</span>
                            <p>10 student between bachelor and master degree</p>
                        </Attributes>
                        <Attributes>
                            <span>Tasks</span>
                            <p>Roadmap definition, task scheduling, Project Management, UX/UI Design</p>
                        </Attributes>
                        <Attributes>
                            <span>Tools</span>
                            <p>Adobe XD, Keynote</p>
                        </Attributes>
                    </RightWrapper>
                </OverviewWrapper>
            </ContainerWrapper>
            <BlackContainerWrapper>
                <ProjectTitle>Product concept</ProjectTitle>
                <ProjectSubText>In a first version, thanks to the data collected from a survey with professional wine tasters, we established most primary functionalities of the application. Yes We Wine is meant to be convenient and compact, possibly substituting for the regular notebook of wine tasters who can report on a tasting experience in a few seconds and compare their sensations to those of hundreds of other professionals. A tasting experience on Yes We Wine covers the steps of a conventional tasting: the user only has to use a hand to record the colour, flavour or mouthfeel aromas of a wine.
               </ProjectSubText>
                <img src={ywwDegustation} alt=""/>
                <ProjectSubText>After getting a tasting score, the users can compare it to other wine tasters’. They can find all their sampling data in their dashboard and classify the recorded tastings as they want. For our users, combining the recreational aspect and the skill enhancement challenge with peers was a key point. We ensured that a gamification process was included, which allowed us afterwards to offer an easier access to the app for the general public and a simplification of the wine tasting concept.
                </ProjectSubText>
                <img src={ywwDashboard} alt=""/>
            </BlackContainerWrapper>
            <ContainerWrapper>
                <ProjectTitle>Community</ProjectTitle>
                <ProjectSubText>The sharing feature was frequently expressed in the testimonies of our users. In order to promote the interest for tasting, we added a news feed where any user can share progress with others or any kind of group tasting experiences for the general public or for professionals.</ProjectSubText>
                <img src={ywwCommunity} alt=""/>
            </ContainerWrapper>
            <WhiteContainerWrapper>
                <ProjectTitle>Business challenge</ProjectTitle>
                <ProjectSubText>At the beginning, the project promoters wished to share and benefit from the amount of data generated by the app, especially for the chateaux and other wine producers for quality analysis and customer satisfaction purposes. We therefore created Yes We Wine Business, a PWA where professional users could observe the data related to their productions around the world and set up alerts to watch the evolution of the tastings.</ProjectSubText>
                <img src={ywwBusiness} alt=""/>
                <ProductEvoWrapper>
                    <div>
                        <div>
                            <span>3000 +</span>
                            <p>users</p>
                        </div>
                        <span>Since the launch of the application in 2019</span>
                    </div>
                    <div>
                        <div>
                            <span>The first</span>
                            <p>collaborative testing tool</p>
                        </div>
                        <span>and first one stop tool to organize remote tastings</span>
                    </div>
                </ProductEvoWrapper>
            </WhiteContainerWrapper>
            <ContainerWrapper>
                <ProjectTitle>Design system</ProjectTitle>
                <ProjectSubText>The design system of Yes We Wine comprises more than 200 elements, all of them centralized in “LA GRAPPE” which reflects the whole flowing and lively identity that we designed around this brand.</ProjectSubText>
                <img src={ywwDSS} alt=""/>
            </ContainerWrapper>
        </>
    );
}

export default YesWeWine;
