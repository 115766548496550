import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
  background: #171717;
  border-radius: 50%;
  height: 100vh;
  width: 100vh;
  position: absolute;
  left: -200px;
  top: -100px;
  z-index: -1;
  @media screen and (max-width: 700px){
    display: none;
  }
`

const IntroText = styled.h1`
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  line-height: 60px;
  margin-top: 150px;
`
const IntroSubText = styled.h2`
  font-size: 20px;
  color:#D1D6DA;
  font-weight: 400;
`
function TopPage() {
    return (
        <>
            <Circle/>
            <IntroText>Hello ! I’m Anna, Product Designer.<br/>I enjoy creating visual design, user-centric experience and human experiences. </IntroText>
            <IntroSubText>Currently working as UX/UI Designer at Sinch</IntroSubText>
        </>
    );
}

export default TopPage;
