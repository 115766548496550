import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LivresDeProches from "./Project/LivresDeProches";
import YesWeWine from "./Project/YesWeWine";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// @ts-ignore
import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <ScrollToTop/>
          <Switch>
              <Route exact path="/" component={App} />
              <Route path="/livresdeproches" component={LivresDeProches} />
              <Route path="/yeswewine" component={YesWeWine}/>
          </Switch>
      </BrowserRouter>,
      <>
          <div>
              <CustomCursor
                  targets={['.Project', '.MenuList']}
                  customClass='custom-cursor'
                  dimensions={45}
                  fill='white'
                  targetScale={1.5}
                  opacity={0.5}
                  smoothness={{
                      movement: 0.2,
                      scale: 0.35,
                      opacity: 1,
                  }}
                  targetOpacity={0.5}
              />
          </div>
      </>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
