import {Button, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Dialog } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {Clear} from "@material-ui/icons";

const ProjectWrapper = styled.a`
  background: #262626;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 155px;
  position: relative;
  margin: 50px auto;
  text-decoration: none;
  transition: all ease-in 1s;
  &:hover{
    & .ProjectImage{
      display: block;
      margin: 0;
      width: auto;
    }
    @media screen and (max-width:700px){
      & .ProjectImage{
        display: none;
      }
    }
    & .sinch{
      color: #FFC658;
    }
    & .ldp{
      color: #FF025B;
    }
    & .yww{
      color: #8F5CFF;
    }

  }
  @media screen and (max-width:800px){
    & .ProjectImage{
      display: none; 
    &:hover{
      display:none;
    }
  }  
}
`
export const ProjectWrapperWhite = styled.a`
  background: white;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 155px;
  position: relative;
  margin: 50px auto;
  text-decoration: none;
  transition: all ease-in 1s;
  & p{
    color: #262626;
  }
`
export const ProjectName = styled.p`
  font-weight: 600;
  font-size: 45px;
  color: #fff;
  width: 60%;
  margin: 0 0 10px 0;
`
export const ProjectCta = styled(Link)`
  font-size: 14px;
  color:#ADB1B5;
  display: flex;
  text-decoration: none;
  align-items: center;
  & svg{
    font-size: 13px;
    margin-left: 5px;
  }
`
const ProjectImage = styled.img`
  height: 230px;
  position: absolute;
  right: 0;
  top:0;
  display: none;
`
const ProjectLink = styled(Link)`
  text-decoration: none;
`

export const Modal = styled(Dialog)`
  &&{
    width: 100%;
  }
`
export const ModalTitle = styled.h2`
  font-size: 25px;
  margin: 0;
  font-weight: 600;
  font-family: Inter, sans-serif;
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  &&{
    padding: 40px;
  }
  & svg{
    position: absolute;
    right: 20px;
    top: 20px;
  }

`
export const LabelPassword = styled.label`
  font-size: 15px;    
  font-weight: 500;
  margin: 20px 0 10px;
`
export const InputPwd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const InputPassword = styled.input`
  background: #F5F5F5;
  border: 0;
  flex: 1;
  padding:19px;
  font-size: 20px;
  @media screen and (max-width: 700px){
    width: 100px;
  }
`
export const PasswordButton = styled(Button)`
  && {
    height: 60px;
    border-radius: 0;
    position: relative;
    background: #1B1B1B;
    color: #fff;
    font-weight: 600;
    padding: 0 20px;
    &:hover{
      background: black;
    }
  }
`
function Project({
    // @ts-ignore
    name,
    // @ts-ignore
    image,
    // @ts-ignore
    pageLink,
    // @ts-ignore
    className
 }) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Modal open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                <ModalContent>
                    <ClearIcon onClick={handleClose} />
                    <ModalTitle>Currently working on</ModalTitle>
                    <LabelPassword>Enter project password</LabelPassword>
                    <InputPwd>
                        <InputPassword type="password"/>
                        <PasswordButton onClick={() => { alert('Permission denied')}} color="primary">Unlock</PasswordButton>
                    </InputPwd>
                </ModalContent>
            </Modal>
            <ProjectLink to={pageLink} onClick={handleClickOpen}>
                <ProjectWrapper id="work" className={className}>
                    <Grid container
                          direction="column"
                          justify="flex-start"
                          alignItems="flex-start">
                        <ProjectName className={className}>{name}</ProjectName>
                        <ProjectCta to={pageLink}>Read the case study <ArrowRightAltIcon/></ProjectCta>
                    </Grid>
                    <ProjectImage className="ProjectImage" src={image}/>
                </ProjectWrapper>
            </ProjectLink>
        </>
    );
}

export default Project;
