import React from 'react';
import 'custom-cursor-react/dist/index.css';
import styled from "styled-components";
import Menu from '../Menu';
import LdpCover from '../assets/ldpCover.png';
import LdpConcept from '../assets/ldpConcept.png';
import LdpGroup from '../assets/ldpGroup.png';
import LdpApp from '../assets/ldpApp.png';
import LdpSociety from '../assets/ldpSociety.png';
import LdpDS from '../assets/ldpDS.png';
import RS1 from '../assets/rs1.png';
import RS2 from '../assets/rs2.png';
import RS3 from '../assets/rs3.png';
import RS4 from '../assets/rs4.png';
import { ContainerWrapper } from '../App';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

export const Circle = styled.div`
  background: #171717;
  border-radius: 50%;
  height: 100vh;
  width: 100vh;
  position: absolute;
  left: -200px;
  top: -100px;
  z-index: -1;
  @media screen and (max-width: 700px){
    display: none;
  }
`

export const ProjectTitle = styled.p`
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  line-height: 60px;
  margin-top: 150px;
  margin-bottom: 35px;
`
export const ProjectSubText = styled.p`
  font-size: 20px;
  color:#D1D6DA;
  font-weight: 400;
  width: 70%;
  line-height: 30px;
  @media screen and (max-width: 800px){
    width: 80%;
  }
`
export const Cover = styled.div`
  position: relative;
  &:after{
    content:"";
    display: block;
    position: absolute;
    background: white;
    height:60%;
    width: 100%;
    bottom: -50px;
    z-index: -1;
  }
    & img{
      width: 80%;
      @media screen and (max-width:700px){
        width: 100%;
      }
      display: block;
      margin: 100px auto;
    }
`
export const OverviewWrapper = styled.div`
  display: flex;
  margin-bottom: 300px;
  @media screen and (max-width: 800px){
    flex-direction: column;
  }
`
export const LeftWrapper = styled.div``
export const RightWrapper = styled.div`
  margin-top: 150px;
`
export const Attributes = styled.div`
  & span{
    color: white;
    font-size: 18px;
    font-weight: 700;
  }
  & p{
    color: #BCBCBC; 
    font-size: 18px;
    font-weight: 400;
  }
  &:nth-of-type(1):after , &:nth-of-type(2):after{
    content: "";
    display:block;
    height: 1px;
    width: 100%;
    background: white;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`
export const BlackContainerWrapper = styled(ContainerWrapper)`
  background: #171717;
  width: 80%;
  padding: 10px 10% 100px;
  @media screen and (max-width: 700px){
    padding: 0 5%;
  }
  & img{
    width: 100%;
    margin: 60px 0 100px;
  }
`

export const WhiteContainerWrapper = styled(BlackContainerWrapper)`
  background: #fff;
  color: black;
  width: 80%;
  & p{
    color: #000;
  }
  & p:nth-of-type(2){
    color:#9C9C9C;
  }
`
export const ProductEvoWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  width: 80%;
  @media all and (max-width: 800px){
    width: 90%;
  }
  & div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    @media all and (max-width: 800px){
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    & span:last-child{
      font-size:20px;
      width: 30%;
      line-height: 30px;
      color: #7D7D7D;
    }
    & div{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & span:first-child{
        font-size: 100px;
        color:#000;
        width: fit-content;
      }
      & p{
        font-size: 30px;
        margin:0;
      }
    }
  }
`
export const GridBrand = styled.div`
  @media screen and (max-width: 800px){
    width: 90%;
  }
`
export const GoBackLink = styled(Link)`
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;
  position: absolute;
  top: 40px;
  transition: all ease .3s;
  &:hover{
    text-decoration: underline;
  }
`
export const Credits = styled.p`
  font-size: 13px;
  color: #fff;
`

function LivresDeProches() {
    return (
        <>
            <ContainerWrapper>
                <GoBackLink to="/">Go back to main page</GoBackLink>
                <Menu/>
                <Circle/>
                <ProjectTitle>Social readers platform</ProjectTitle>
                <ProjectSubText>Livres de Proches is a French social reading platform. It is a free book exchange platform between booklovers. Each user can scan their own bookshelves in order to lend and borrow from other bookworms</ProjectSubText>
            </ContainerWrapper>
            <Cover>
                <img src={LdpCover} alt=""/>
            </Cover>
            <ContainerWrapper>
                <OverviewWrapper>
                    <LeftWrapper>
                        <ProjectTitle>Overview</ProjectTitle>
                        <ProjectSubText>I started working on Livres de Proches 4 years ago for my first internship. I was first of all UX/UI Designer for the project as long as I was working with my mentor. Two years ago I took the Lead in Product Design. <br/>My job consisted in defining the next product evolutions, understand user needs, and put them in correlation with business needs.</ProjectSubText>
                    </LeftWrapper>
                    <RightWrapper>
                        <Attributes>
                            <span>Teams</span>
                            <p>3-5 people including a community manager, developers</p>
                        </Attributes>
                        <Attributes>
                            <span>Tasks</span>
                            <p>Interface Design, Brand Content, User Research, Motion Design, Front End Integration,...</p>
                        </Attributes>
                        <Attributes>
                            <span>Tools</span>
                            <p>Sketch, In Vision, Adobe Creative Suit, HTML, CSS, Jinja</p>
                        </Attributes>
                    </RightWrapper>
                </OverviewWrapper>
            </ContainerWrapper>
            <ContainerWrapper>
                <ProjectTitle>Product Challenge</ProjectTitle>
                <ProjectSubText>When I started at Livres de Poches, the main feature was book sharing between people knowing their email addresses. Everything was not really developed to allow dynamic discussion between the users. Moreover users were’nt able to share their shelves with friends. Nothing helped them to easily find books to exchange.</ProjectSubText>
                <img src={LdpConcept} alt=""/>
            </ContainerWrapper>
            <BlackContainerWrapper>
                <ProjectTitle>Inter-user connexions</ProjectTitle>
                <ProjectSubText>To help users connect between themselves our first feature implemented was groups. Groups made users more connected helping them sharing their shelves in a group with friends, people loving the same books,...</ProjectSubText>
                <img src={LdpGroup} alt=""/>
            </BlackContainerWrapper>
            <ContainerWrapper>
                <ProjectTitle>Mobile application</ProjectTitle>
                <ProjectSubText>Setting up readers groups greatly helped to create social links between the readers. However, restricting the use of Livres de Proches on a computer only seems to be a hindrance. <br/>Therefore, we launched an improved version of the mobile application with all the functionalities available on a computer. Initially the mobile app only enabled to scan books. By releasing this iOS and Android application, we hoped to reach more users, by offering the option of connection among readers without any e-mail address and through an inner messaging platform in the app.</ProjectSubText>
                <img src={LdpApp} alt=""/>
            </ContainerWrapper>
            <WhiteContainerWrapper>
                <ProjectTitle>Product evolution</ProjectTitle>
                <ProjectSubText>As we implemented a lot of improvements, we realised that the number of new accounts and  added books had increased. Thus  showing how those implementations greatly contributed and  helped the product to convert more users.</ProjectSubText>
                <ProductEvoWrapper>
                    <div>
                        <div>
                            <span>31240 +</span>
                            <p>accounts</p>
                        </div>
                        <span>Increased from 9100 in 2019 to 31240 in 2021</span>
                    </div>
                    <div>
                        <div>
                            <span>286513 +</span>
                            <p>registered books</p>
                        </div>
                        <span>Increased from 50 000 in 2019 to 286513 in 2021</span>
                    </div>
                </ProductEvoWrapper>
            </WhiteContainerWrapper>
            <ContainerWrapper>
                <ProjectTitle>School and university</ProjectTitle>
                <ProjectSubText>The next step after the rise in the number of the tool users was to find a solution for the monetization in accordance to the values of our project. Therefore, no internal advertising and no paying accounts were possible options. That’s why we thought about launching an offer for schools and professionals. The aim was to set up a shared library for all the students from a campus or the employees of a company …</ProjectSubText>
                <img src={LdpSociety} alt=""/>
            </ContainerWrapper>
            <ContainerWrapper>
                <ProjectTitle>Design System</ProjectTitle>
                <ProjectSubText>The implementation of a design system in Livres de Proches dates back to 2017.Indeed, it was important to simplify the collaboration between developers and designers to integrate new contents on the site. This design system includes a significant number of the main elements of the internet website.</ProjectSubText>
                <img src={LdpDS} alt=""/>
            </ContainerWrapper>
            <WhiteContainerWrapper>
                <ProjectTitle>Brand Content</ProjectTitle>
                <ProjectSubText>The promotion of Livres de Proches on social networks is essential. I worked on a great number of posts on the following networks: Facebook,Instagram,Twitter. I also contributed to the Youtube videos relating events and interviews organized by Livres de Proches . Then, I worked and collaborated to the making of a motion video about the concept of the application.</ProjectSubText>
                    <GridBrand>
                        <Grid container >
                            <Grid item xs>
                                <div>
                                    <img src={RS1} alt=""/>
                                </div>
                            </Grid>
                            <Grid item xs>
                                <div>
                                    <img src={RS2} alt=""/>
                                </div>
                            </Grid>
                            <Grid item xs>
                                <div>
                                    <img src={RS3} alt=""/>
                                </div>
                            </Grid>
                            <Grid item xs>
                                <div>
                                    <img src={RS4} alt=""/>
                                </div>
                            </Grid>
                        </Grid>
                    </GridBrand>
                <iframe width="100%" height="700"
                        src="https://www.youtube.com/embed/videoseries?list=PLXu0PdNRZqiMV2zc5W_bqGEDejjkflaIm"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </WhiteContainerWrapper>
            <ContainerWrapper>
                <ProjectTitle>Credits</ProjectTitle>
                <Credits>© Illustration by Stéphanie Radavidson - YAAL</Credits>
            </ContainerWrapper>
        </>
    );
}

export default LivresDeProches;